// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Button/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Button/index.tsx");
  import.meta.hot.lastModified = "1741664317000";
}
// REMIX HMR END

import { CircularProgress } from '@mui/material';
import React, { forwardRef } from 'react';
import { cn } from '~/utils';
const styles = {
  primary: 'bg-bluedark text-doctor border-bluedark',
  secondary: 'bg-transparent text-bluedark border-bluedark',
  tertiary: 'bg-bluelight text-doctor border-bluelight',
  outline: 'bg-transparent border-[1.5px] border-bluedark',
  plain: 'bg-transparent text-bluedark'
};
// to be able show tooltip: https://mui.com/material-ui/react-tooltip/#custom-child-element
const Button = forwardRef(_c = ({
  children,
  variants = 'primary',
  className,
  isLoading = false,
  ...props
}, ref) => {
  return <button {...props} ref={ref} className={cn(`flex justify-center items-center rounded gap-x-2 text-sm border-[1.5px] px-4 py-2 ${styles[variants]}`, props.disabled && 'opacity-50 cursor-not-allowed', className)}>
        {isLoading && <CircularProgress color="inherit" size={16}></CircularProgress>}
        {children}
      </button>;
});
_c2 = Button;
Button.displayName = 'Button';
export default Button;
var _c, _c2;
$RefreshReg$(_c, "Button$forwardRef");
$RefreshReg$(_c2, "Button");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;